import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'LoginPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			loginRequest: {},
			errorBag: {}
		};

	},
	components: {
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {
		login() {
			this.ss.login(this.loginRequest)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						this.$router.push('/Principal');
						this.$router.go();
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
		},
		editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
		authenticate() {
			if (!this.$msal.isAuthenticated()) {
				this.$msal.signIn();
			}
		}
	},
	mounted() {
		var persona = JSON.parse(localStorage.getItem('persona'));
		if (persona) {
			localStorage.clear();
		}
		if (this.$msal.isAuthenticated()) {
			console.log(this.$msal.data);
			let loginTokenRequest = {
				"token": this.$msal.data.user.accountIdentifier,
				"email": this.$msal.data.user.userName,
			};
			this.ss.loginToken365(loginTokenRequest)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						this.$router.push('/Libro');
						location.reload();
					}
				)
				.catch((error) => {

					this.errorBag = error.response.data.errors;
					let persona = {
						Persona:this.$msal.data.user.idToken.name,
						email:this.$msal.data.user.idToken.preferred_username,
						
					}
					//crear a la persona
					console.log("entro al error")
					this.ss.storeLogin(persona)
						.then(
							(result) => {
								console.log(result, "sebas theb");
								this.ss.loginToken365(loginTokenRequest)
								.then(
									(result) => {
										console.log(result, 'primer check');
										localStorage.setItem('persona', JSON.stringify(result.data.data));
										localStorage.setItem('token', JSON.stringify(result.data.token));
										
										this.$router.push('/CompletarPerfil');
										location.reload();
									}
								)
								.catch((error) => {
									console.log(error)
								});
							}
						)
						.catch((error) => {
							this.errorBag = error.response.data.errors;
							this.$bvToast.toast(
								'Problema al Iniciar Sesion',
								{
									title: 'Error',
									variant: 'danger',
									autoHideDelay: 5000
								}
							)
						});
					
					//this.ss.loginToken
					console.log(persona, 'hola');
					

					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
					//this.$msal.signOut();

				});
		} else {
			console.log("no esta logueado")
		}
	}
}